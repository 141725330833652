'use client'
import { FormControl, TextField, IconButton } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import styles from './index.module.scss'
import { useSharedData } from 'bossjob-remote/dist/hooks'
import { isEqual, cloneDeep } from 'lodash-es'
import JobItem from './item'
import Header from './header'
import classNames from 'classnames'
import MaterialButton from 'components/MaterialButton'
import Text from 'components/Text'
import { useRef } from 'react'
import { debounce } from 'lodash-es'
import { uniqBy, prop } from 'ramda'
import { Empty } from 'antd'
import InputAdornment from '@mui/material/InputAdornment'
import { Input } from 'antd'
import { getCountryKey } from 'helpers/country'
const countryKey = getCountryKey()
function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $&表示整个匹配的字符串
}
const filterTree = (tree, condition, parentName, inputValue = '') => {
  var result = []
  for (var i = 0; i < tree.length; i++) {
    var node = tree[i]

    const nodeName = parentName ? parentName + ' / ' + node.value : node.value
    if (condition(node)) {
      node.value = nodeName

      const newNode = cloneDeep(node)
      var text = newNode.value.toLocaleLowerCase()
      var keyword = inputValue.toLocaleLowerCase()
      if (text.indexOf(keyword) > -1) {
        var re = new RegExp(escapeRegExp(keyword), 'gi')
        newNode.value = newNode.value.replace(re, function (matched) {
          return '<span style="color:#2378e5">' + matched + '</span>'
        })
      }
      result.push(newNode)
    }
    if (result.length >= 8) {
      break
    }
    if (node.locations && countryKey !== 'sg' && node.locations?.length > 1) {
      var childResults = filterTree(node.locations, condition, nodeName, inputValue)
      result = result.concat(childResults)
    }

    if (result.length >= 8) {
      break
    }
  }
  console.log({ result })
  return result
}

interface ActionBtnProps extends React.HTMLProps<HTMLButtonElement> {
  text: string
  variant?: 'text' | 'outlined' | 'contained'
  background?: string
  boxShadow?: string
}

const ActionBtn: React.FC<ActionBtnProps> = ({ text, onClick, variant = 'outlined', ...rest }) => {
  return (
    <MaterialButton
      variant={variant}
      capitalize
      onClick={onClick}
      sx={{ height: '44px', borderRadius: '4px', ...rest }}
    >
      {text}
    </MaterialButton>
  )
}

const LocationMultiSelector = (props: any) => {
  const {
    label,
    className,
    onSelect,
    value,
    searchValueLocations,
    isMobile,
    onChange,
    lang,
    isPC,
    top,
    inputFontSize = '14px',
    position,
    left = '-12px',
    ...rest
  } = props
  const [showModal, setShowModal] = useState(false)
  const [firstRender, setFirstRender] = useState(true)
  const [isClosing, setIsClosing] = useState(false)
  const [activeFirst, setActiveFirst] = useState<any>()
  const [inputValue, setInputValue] = useState('')
  const [locationList, setLocationList] = useState([])
  const [inputFocus, setInputFocus] = useState(false)
  const [mobileSaveLen, setMobileSaveLen] = useState('')
  const initLocationRef = useRef([])
  const [mobileFocus, setMobileFocus] = useState(false)
  const secondList = useMemo(() => {
    return inputValue ? [] : activeFirst?.locations ?? []
  }, [activeFirst])
  const config = useSharedData('CONFIG')
  const allLang = useSharedData('DICTIONARY') ?? {}
  // const locationList = config?.location_lists || []

  useEffect(() => {
    if (config?.location_lists?.length > 0) {
      const arr = config?.location_lists.map((e) => ({
        ...e,
        value: e.display_name
      }))
      initLocationRef.current = arr
      if (inputValue) {
        const cloneArr = cloneDeep(arr)
        const filterArr = filterTree(
          cloneArr,
          function (node) {
            return (
              node.value &&
              node.value.toLocaleLowerCase()?.indexOf(inputValue.toLocaleLowerCase()) > -1
            )
          },
          '',
          inputValue
        )
        setActiveFirst([])
        setLocationList(filterArr)
        return
      }
      setLocationList([...arr])
    }
  }, [config, inputValue])

  const [locations, setLocations] = useState<any[]>([])
  const locationIds = useMemo(() => {
    // setLocation(locations)
    return locations.map((item) => item?.id)
  }, [locations])
  const [height, setHeight] = useState(0)
  useEffect(() => {
    const setOverflow = (value: 'auto' | 'hidden') => {
      document.body.style.overflow = value
      // let style = document.documentElement?.style
      // if (style) {
      //   style.overflow = value
      // }
    }

    if (!isMobile) {
      setOverflow('auto')
      return
    }

    if (showModal) {
      setOverflow('hidden')
    } else {
      setOverflow('auto')
      setActiveFirst(null)
      setInputValue('')
    }
  }, [showModal, isMobile])
  useEffect(() => {
    const convertStyle = debounce(() => {
      setHeight(window.innerHeight)
    }, 50)
    setHeight(window.innerHeight)
    window.addEventListener('resize', convertStyle)
    window.addEventListener('DOMContentLoaded', convertStyle)
    return () => {
      window.removeEventListener('resize', convertStyle)
      window.removeEventListener('DOMContentLoaded', convertStyle)
    }
  }, [])

  useEffect(() => {
    if (!isEqual(value, locations)) {
      setLocations(value?.filter?.((a) => a) ?? [])
    }
  }, [value])
  const preShowModal = useRef(false)
  useEffect(() => {
    if (!showModal && preShowModal.current && !isMobile) {
      onChange?.(locations)
    }
    preShowModal.current = showModal
  }, [showModal])
  useEffect(() => {
    onSelect?.(locations)
  }, [locations])
  const textValue = useMemo(() => {
    return locationIds?.join(',')
  }, [locationIds])

  const isSecondSelected = useCallback(
    (second) => {
      return !!locations.find((item) => item.id === second.id)
    },
    [locations]
  )

  const isFirstSelected = useCallback(
    (first) => {
      return first.locations
        ? !first.locations?.find((item) => !isSecondSelected(item))
        : !!locations.find((item) => item.id === first.id)
    },
    [locationList, isSecondSelected]
  )

  const isFirstHalfSelected = useCallback(
    (first) => {
      return first.locations?.find((item) => isSecondSelected(item))
    },
    [locationList, isSecondSelected]
  )

  const onFirstClick = useCallback(
    (first) => {
      if (isFirstSelected(first)) {
        setLocations(
          locations.filter((item) =>
            first.locations
              ? !first.locations.map((item) => item.id).includes(item.id)
              : !(item.id === first.id)
          )
        )
        if (!first.locations) {
          setShowModal(false)
        }
      } else {
        if (first?.locations) {
          setLocations(uniqBy(prop('id'), [...locations, ...first?.locations]))
        } else {
          setLocations(uniqBy(prop('id'), [...locations, first]))
          setShowModal(false)
        }
      }
      setInputValue('')
    },
    [isFirstSelected, locations]
  )

  const onSecondClick = useCallback(
    (second) => {
      if (locationIds.includes(second.id)) {
        setLocations(locations.filter((location) => location.id !== second.id))
      } else {
        setLocations([...locations, second])
      }
    },
    [locations]
  )

  const listener = useCallback((e) => {
    if (e.target.id?.includes?.('job-item') || e.target.nodeName == 'INPUT') {
      return
    }
    setShowModal(false)
    document.removeEventListener('click', listener)
  }, [])
  useEffect(() => {
    if (!isMobile) {
      if (showModal) {
        setTimeout(() => {
          document.addEventListener('click', listener)
        }, 1000)
      } else {
        document.removeEventListener('click', listener)
      }
      return () => document.removeEventListener('click', listener)
    }
  }, [showModal])
  const onBack = useCallback(() => {
    setActiveFirst(undefined)
  }, [])
  const clear = useCallback(() => {
    setLocations([])
  }, [value])

  const animtionClose = useCallback(() => {
    setLocationList([...initLocationRef.current])
    setIsClosing(true)
    setTimeout(() => {
      setShowModal(false)
      setIsClosing(false)
      setFirstRender(true)
    }, 300)
  }, [])
  const onClose = useCallback(() => {
    clear()
    animtionClose()
  }, [clear, animtionClose])

  const onSave = useCallback(() => {
    onChange?.(locations)
    animtionClose()
    setInputValue('')
  }, [onChange, locations, animtionClose])

  const onFirstHover = useCallback(
    (hoverSecondList) => {
      if (firstRender) {
        setFirstRender(false)
      }
      if (activeFirst?.id !== hoverSecondList.id) {
        setActiveFirst(hoverSecondList)
      }
    },
    [activeFirst]
  )

  // why add searchValueLocations into this logic?
  // the textValue generated by locationIds, and locationIds is lazy compute with config, config will be fetched when page loaded.
  // therefore, there is a delay time when calculate textValue
  const valueText = useMemo(() => {
    const selected = textValue.split(',').filter((a) => a)
    let length = selected?.length || searchValueLocations?.length
    if (length > 99) length = '99+'
    if (isMobile) {
      setMobileSaveLen(length ? `(${length})` : '')
    }
    return `${label} ${length ? `(${length})` : ''}`
  }, [textValue, searchValueLocations])

  const handleCancel = () => {
    setInputValue('')
    setLocationList([...initLocationRef.current])
  }

  return (
    <FormControl
      className={className}
      size='small'
      onClick={(e) => {
        // @ts-ignore
        if (e.target.nodeName == 'INPUT') return
        e.preventDefault()
        e.stopPropagation()
        setShowModal(!showModal)
      }}
    >
      <TextField
        value={inputFocus ? inputValue : valueText}
        autoComplete='off'
        label={<div style={{ position: 'relative', bottom: 7 }}>{label}</div>}
        style={{
          background: textValue.split(',').filter((a) => a)?.length ? '#E7F1FB' : '#F0F0F0',
          borderRadius: '10px'
        }}
        placeholder={valueText}
        inputProps={{
          style: {
            color: position
              ? 'rgba(0, 0, 0, 0.6)'
              : textValue.split(',').filter((a) => a)?.length
                ? '#1D2129'
                : '#86909C',
            background: textValue.split(',').filter((a) => a)?.length ? '#fff' : '#ffff',
            textAlign: 'left',
            fontSize: inputFontSize,
            paddingLeft: '10px'
          }
        }}
        onFocus={() => {
          setShowModal(true)
          setInputFocus(true)
        }}
        onBlur={() => {
          setInputFocus(false)
          //  setInputValue('')
        }}
        onChange={(e) => setInputValue(e.target.value)}
        InputProps={{
          readOnly: isMobile ? true : false,
          endAdornment: (
            <InputAdornment position='end'>
              {/* <KeyboardArrowDownIcon
            style={{ color: '#1D2129', position: 'relative', left: '-8px' }}
          /> */}
              <IconButton>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='11'
                  height='7'
                  viewBox='0 0 11 7'
                  fill='none'
                >
                  <path
                    d='M9.46298 0.966675C10.3706 0.966675 10.8088 2.07844 10.1453 2.69773L6.18232 6.39651C5.79811 6.7551 5.20189 6.7551 4.81768 6.39651L0.854703 2.69773C0.191182 2.07844 0.629399 0.966674 1.53702 0.966674L9.46298 0.966675Z'
                    fill='#86909C'
                  />
                </svg>
              </IconButton>
            </InputAdornment>
          )
        }}
        // disabled={showModal}
        // onFocus={(e) => {
        //   e.preventDefault()
        //   e.stopPropagation()
        //   if (!isTouched) {
        //     setShowModal(true)
        //   }
        //   rest?.onFocus?.(e)
        //   Promise.resolve().then(() => {
        //     e.target.blur()
        //   })
        // }}
        {...rest}
      />
      {showModal && (
        <div
          className={styles.container}
          style={{ top: top ? top : 55, left: left }}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
          onScroll={(e) => {
            e.stopPropagation()
            e.preventDefault()
          }}
        >
          <div
            className={styles.column}
            onScroll={(e) => {
              e.stopPropagation()
              e.preventDefault()
            }}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
            }}
          >
            {locationList?.length > 0 ? (
              locationList?.map((first) => {
                return (
                  <JobItem
                    key={first.id}
                    data={{ ...first, value: first.value }}
                    active={activeFirst?.id === first.id}
                    checked={isFirstSelected(first)}
                    halfChecked={isFirstHalfSelected(first)}
                    onMouseOver={() => onFirstHover(first)}
                    onClick={() => {
                      onFirstClick(first)
                    }}
                    noArrow={!(first?.locations?.length > 1)}
                    inputValue={inputValue}
                  />
                )
              })
            ) : (
              <div className={styles.emptyBox}>
                <Empty />
              </div>
            )}
          </div>
          {secondList.length > 1 && (
            <div
              className={styles.column}
              onScroll={(e) => {
                e.stopPropagation()
                e.preventDefault()
              }}
            >
              {secondList.map((second: any) => {
                return (
                  <JobItem
                    key={second.id}
                    data={second}
                    checked={isSecondSelected(second)}
                    onClick={() => {
                      onSecondClick(second)
                    }}
                    noArrow
                  />
                )
              })}
            </div>
          )}
        </div>
      )}
      {showModal && isMobile && (
        <div
          className={classNames({
            [styles.mobile]: true,
            [styles.showModal]: firstRender,
            [styles.closingModal]: isClosing
          })}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
          style={{ height: height - 67 }}
        >
          <div className={styles.topContainer}>
            <div
              className={classNames({
                [styles.firstPage]: true,
                //   [styles.show]: !!activeFirst && !firstRender && !mobileFocus && !inputValue,
                [styles.hide]: !activeFirst && !firstRender
              })}
            >
              <Header title={label} onClose={animtionClose}></Header>
              <div className={styles.mobileHeaderSearch}>
                <Input
                  placeholder='search'
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  onFocus={() => setMobileFocus(true)}
                  onBlur={() => setMobileFocus(false)}
                  prefix={
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='20'
                      height='21'
                      viewBox='0 0 20 21'
                      fill='none'
                    >
                      <path
                        d='M8.75 15.5C12.2018 15.5 15 12.7018 15 9.25C15 5.79824 12.2018 3 8.75 3C5.29824 3 2.5 5.79824 2.5 9.25C2.5 12.7018 5.29824 15.5 8.75 15.5Z'
                        stroke='#86909C'
                        strokeWidth='1.5'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M13 14L15 16'
                        stroke='#86909C'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  }
                />
                {/* <div className={styles.cancel} onClick={() => handleCancel()}>
                  {allLang?.comm?.cancel}
                </div> */}
              </div>

              <div
                className={styles.columnMain}
                style={{ height: height - 200, paddingBottom: '70px' }}
              >
                {locationList?.length > 0 ? (
                  locationList.map((first) => {
                    return (
                      <JobItem
                        key={first.id}
                        data={{ ...first, value: first.value }}
                        active={activeFirst?.id === first.id}
                        checked={isFirstSelected(first)}
                        halfChecked={isFirstHalfSelected(first)}
                        onArrowClick={(e) => {
                          e.stopPropagation()
                          onFirstHover(first)
                        }}
                        onClick={(e) => {
                          e.stopPropagation()
                          onFirstClick(first)
                        }}
                        inputValue={inputValue}
                        noArrow={!(first?.locations?.length > 1)}
                      />
                    )
                  })
                ) : (
                  <div className={styles.emptyBox}>
                    <Empty />
                  </div>
                )}
              </div>
            </div>
            <div
              className={classNames({
                [styles.secondPage]: true,
                [styles.show]: !!activeFirst && !firstRender && activeFirst?.locations?.length > 1,
                [styles.hide]:
                  (!activeFirst && !firstRender) || !(activeFirst?.locations?.length > 1)
              })}
            >
              <Header
                title={activeFirst?.display_name}
                onBack={onBack}
                onClose={() => {
                  handleCancel()
                  onClose()
                }}
              ></Header>
              {/* <div className={styles.subContainer} style={{ height: height - 59 - 75 }}> */}
              <div className={styles.secondContainer} style={{ height: height - 130 }}>
                {secondList.length > 1 && (
                  <div className={styles.columnSub} style={{ height: '100%', overflowY: 'scroll' }}>
                    {secondList.map((second: any) => {
                      return (
                        <JobItem
                          key={second.id}
                          data={second}
                          checked={isSecondSelected(second)}
                          onArrowClick={(e) => {
                            e.stopPropagation()
                          }}
                          onClick={() => {
                            onSecondClick(second)
                          }}
                          noArrow
                        />
                      )
                    })}
                  </div>
                )}
                {/* </div> */}
              </div>
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <div
              className={styles.resetBtn}
              onClick={clear}
              style={{
                width: 'calc(35% - 12px)'
              }}
            >
              {lang?.reset2}
            </div>

            <ActionBtn
              variant='contained'
              onClick={onSave}
              width={'65%'}
              text={lang?.save + `${mobileSaveLen} `}
              color='#fff'
              boxShadow='none'
            />
          </div>
        </div>
      )}
    </FormControl>
  )
}

export default LocationMultiSelector
